// {{{EXTERNAL}}}
import React, { forwardRef } from "react";
import { Input, createTheme, rem, Checkbox, Radio, Anchor, Switch, List, AvatarGroup, Avatar, SimpleGrid, Paper, Stack, Group, Flex, Text, AppShell, Title, Overlay, Progress, Dialog, ModalRoot, Modal, Center, Button, Grid, Menu, Tooltip, Badge, Kbd, Select, SegmentedControl, MantineThemeComponents, Accordion, Affix, Image, Combobox, RadioIndicator, MultiSelect, Pill, Divider, Container, Loader, MantineLoaderComponent, Slider, Indicator, Drawer, Burger, Tabs } from "@mantine/core";
// {{{ASSETS}}}
import LoaderStars from "./assets/img/loader.stars.svg"
// {{{STYLES}}}
import * as  buttonClasses from './styles/mantine/Button.module.css';
import * as  avatarClasses from './styles/mantine/Avatar.module.css';
import * as  switchClasses from './styles/mantine/Switch.module.css';
import * as  radioClasses from './styles/mantine/Radio.module.css';
import * as  radioIndicatorClasses from './styles/mantine/RadioIndicator.module.css';
import * as  checkBoxClasses from './styles/mantine/Checkbox.module.css';
import * as  anchorClasses from './styles/mantine/Anchor.module.css';
import * as  listClasses from './styles/mantine/List.module.css';
import * as  appShellClasses from './styles/mantine/AppShell.module.css';
import * as  titleClasses from './styles/mantine/Title.module.css';
import * as  toolTipClasses from './styles/mantine/Tooltip.module.css';
import * as  menuClasses from './styles/mantine/Menu.module.css';
import * as  segmentedControlClasses from './styles/mantine/SegmentedControl.module.css';
import * as  affixClasses from './styles/mantine/Affix.module.css';
import * as  textClasses from './styles/mantine/Text.module.css';
import * as  comboboxClasses from './styles/mantine/Combobox.module.css';
import * as  selectClasses from './styles/mantine/Select.module.css';
import * as  kbdClasses from './styles/mantine/Kbd.module.css';
import * as  modalClasses from './styles/mantine/Modal.module.css';
import * as  drawerClasses from './styles/mantine/Drawer.module.css';
import * as  multiSelectClasses from './styles/mantine/MultiSelect.module.css';
import * as  dividerClasses from './styles/mantine/Divider.module.css';
import * as  containerClasses from './styles/mantine/Container.module.css';
import * as  accordionClasses from './styles/mantine/Accordion.module.css';
import * as  inputClasses from './styles/mantine/Input.module.css';
import * as  sliderClasses from './styles/mantine/Slider.module.css';
import * as  indicatorClasses from './styles/mantine/Indicator.module.css';
import * as  burgerClasses from './styles/mantine/Burger.module.css';
import * as  badgeClasses from './styles/mantine/Badge.module.css';
import * as  tabClasses from './styles/mantine/Tab.module.css';


const LoaderStarsMantine: MantineLoaderComponent = forwardRef(
  ({ ...others },
  ref
) => (
  <LoaderStars
    {...others}
    ref={ref}
    style={{ width: 60, height: 60 }}
  />

));

LoaderStarsMantine.displayName = "@superstardle/LoaderGridMantine"


const components = {
  Loader: Loader.extend({
    defaultProps: {
      loaders: { ...Loader.defaultLoaders, dots: LoaderStarsMantine },
      type: 'dots',
    },
  }),
  Tabs: Tabs.extend({
    classNames: tabClasses
  }),
  Slider: Slider.extend({
    classNames: sliderClasses
  }),
  Indicator: Indicator.extend({
    classNames: indicatorClasses
  }),
  Input: Input.extend({
    classNames: inputClasses
  }),
  Burger: Burger.extend({
    classNames: burgerClasses
  }),
  Accordion: Accordion.extend({
    classNames: accordionClasses
  }),
  Container: Container.extend({
    classNames: containerClasses
  }),
  Divider: Divider.extend({
    defaultProps: {
      w: "100%"
    },
    classNames: dividerClasses
  }),
  Combobox: Combobox.extend({
    classNames: comboboxClasses
  }),
  Affix: Affix.extend({
    classNames: affixClasses
  }),
  Checkbox: Checkbox.extend({
    classNames: checkBoxClasses
  }),
  Radio: Radio.extend({
    classNames: radioClasses
  }),
  RadioIndicator: RadioIndicator.extend({
    classNames: radioIndicatorClasses
  }),
  Anchor: Anchor.extend({
    classNames: anchorClasses
  }),
  Switch: Switch.extend({
    classNames: switchClasses
  }),
  List: List.extend({
    defaultProps: {
      withPadding: false,
      listStyleType: "circle"
    },
    classNames: listClasses
  }),
  AvatarGroup: AvatarGroup.extend({
    defaultProps: {
    }
  }),
  Avatar: Avatar.extend({
    defaultProps: {
      size: 72,
      radius: 48
    },
    classNames: avatarClasses
  }),
  SimpleGrid: SimpleGrid.extend({
    defaultProps: {
      spacing: rem(1),
      verticalSpacing: rem(1)
    }
  }),
  Paper: Paper.extend({
    defaultProps: {
      shadow: 'none',
      radius: rem(0),
      p: rem(0),
      m: rem(0)
    }
  }),
  Stack: Stack.extend({
    defaultProps: {
      gap: rem(0)
    }
  }),
  Group: Group.extend({
    defaultProps: {
      gap: rem(0)
    }
  }),
  Flex: Flex.extend({
    defaultProps: {
      gap:0,
      wrap: "nowrap",
      direction: "row",
      justify: "center",
      align: "center"
    }
  }),
  Text: Text.extend({
    defaultProps: {
      variant: "light"
    },
    classNames: textClasses
  }),
  AppShell: AppShell.extend({
    classNames: appShellClasses
  }),
  Title: Title.extend({
    defaultProps: {
      variant: "light"
    },
    classNames: titleClasses
  }),
  Overlay: Overlay.extend({
    defaultProps: {
      color: "var(--mantine-color-superstardle-dark-0)",
      backgroundOpacity: 0.66,
      blur: 3
    }
  }),
  Progress: Progress.extend({
    defaultProps: {
      radius: 0,
      size: "sm"
    },
    styles: {
      root: {
        backgroundColor: "transparent"
      }
    }
  }),
  Dialog: Dialog.extend({
    defaultProps: {
      radius: rem(6),
    },
    styles: {
      root: {
        border: '0.0625rem solid rgba(52, 67, 86, 0.15)',
        padding: `0 !important`,
        background: "#e9eef7",
        maximumHeight: rem(300),
        width: "100%",
        maxWidth: "inherit"
      }
    }
  }),
  ModalRoot: ModalRoot.extend({
    defaultProps: {
      radius: 8,
      centered: true,
      size: 500,
      transitionProps: {
        transition: 'fade',
        duration: 50
      },
      zIndex: 301
    }
  }),
  Modal: Modal.extend({
    classNames: modalClasses
  }),
  Drawer: Drawer.extend({
    classNames: drawerClasses
  }),
  Image: Image.extend({
    defaultProps: {
      w: "auto"
    }
  }),
  Center: Center.extend({
    defaultProps: {
      w: "100%",
      h: "100%"
    }
  }),
  Button: Button.extend({
    defaultProps: {
      variant: "light"
    },
    classNames: buttonClasses
  }),
  Grid: Grid.extend({
    defaultProps: {
      gutter: rem(0)
    }
  }),
  Menu: Menu.extend({
    defaultProps: {
      radius: 6,
      withinPortal: true,
      closeOnItemClick: true,
      position: "bottom-end",
      shadow: "sm",
      width: 240,
      zIndex: 201
    },
    classNames: menuClasses
  }),
  Tooltip: Tooltip.extend({
    defaultProps: {
      withArrow: true,
      events: {
        hover: true,
        focus: true,
        touch: true
      },
      multiline: true
    },
    classNames: toolTipClasses
  }),
  Badge: Badge.extend({
    classNames: badgeClasses
  }),
  Kbd: Kbd.extend({
    classNames: kbdClasses
  }),
  Pill: Pill.extend({
    defaultProps: {
      size: "md"
    }
  }),
  Select: Select.extend({
    classNames: selectClasses
  }),
  MultiSelect: MultiSelect.extend({
    defaultProps: {
      checkIconPosition: "left",
      maxDropdownHeight: 200
    },
    classNames: multiSelectClasses
  }),
  SegmentedControl: SegmentedControl.extend({
    classNames: segmentedControlClasses
  }),
} as MantineThemeComponents

export const theme = createTheme({
  scale: 1,
  components: components,
  colors: { // [ light0 ... light4, dark0 ... dark5 ] only two shades used
    'superstardle-dark': ['#0b193c', '#0b193c', '#0b193c', '#0b193c', '#0b193c', '#0b193b', '#0b193b', '#0b193b', '#0b193b', '#0b193b'],
    'superstardle-light': ['#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7', '#e9eef7'],
    'superstardle-dim': ['#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5', '#aaafb5'],
    'superstardle-yellow': ['#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400', '#fb9400'],
    'superstardle-red': ['#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5', '#fca5a5'],
    'superstardle-green': ['#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179', '#3ce179'],
    'superstardle-comet': ['#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD','#B8D3DD'],
    'superstardle-planet-red': ['#FF5448','#FF5448','#FF5448','#FF5448','#FF5448','#FF5448','#FF5448','#FF5448','#FF5448','#FF5448'],
    'superstardle-planet-purple': ['#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9','#AFA4F9'],
    'superstardle-planet-green': ['#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC','#6AE5CC'],
    'superstardle-planet-blue': ['#99C8FA ','#99C8FA','#99C8FA','#99C8FA','#99C8FA','#99C8FA','#99C8FA','#99C8FA','#99C8FA','#99C8FA'],
    'superstardle-planet-yellow': ['#EA712E ','#EA712E','#EA712E','#EA712E','#EA712E','#EA712E','#EA712E','#EA712E','#EA712E','#EA712E']
  },
  fontFamily: '"Lato", sans-serif',
  headings: { fontFamily: '"Poppins", sans-serif' },
});
